import { Button, Card, Divider, Empty, message, Skeleton, Tabs } from "antd";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ChannelType } from "../../contexts/channels/ChannelProvider";
import {
  PaymentContext,
  PaymentContextProvider,
  PaymentContextType,
} from "../../contexts/payment/PaymentContext";
import { thousandSeparator } from "../../helpers/thousand_separator";
import { ChannelModel } from "../../models/ChannelModel";
import { PaymentInterfaceParam } from "../channels/Channel";
import "./payment.css";
import {
  DonationSingleCartResultInterface,
  DonationSingleResultInterface,
} from "../../contexts/payment/interfaces/donation-single-result.interface";
import axios from "axios";
import { CompanySingleResultInterface } from "../../models/CompanyModel";

function PaymentMidtransCart() {
  return (
    <PaymentContextProvider>
      <PaymentState />
    </PaymentContextProvider>
  );
}

function PaymentState() {
  const [channel, setChannel] = React.useState<ChannelModel>();
  const {
    paymentResult,
    doPaymentCartV2,
    loading,
    transactionModel,
    cancelPayment,
    initPayment,
    transactionStatus,
    transactionStatusModel,
    setDonationCartResultV2,
  } = React.useContext(PaymentContext) as PaymentContextType;
  const [vaNumber, setVaNumber] = React.useState<string | undefined>(
    "123456789"
  );
  const [qrCode, setQrCode] = React.useState<string>();
  const [deeplink, setDeeplink] = React.useState<string>();
  const [bankCode, setBankCode] = React.useState<string>();
  const [donationCartResult, setDonationCartResult] =
    React.useState<DonationSingleCartResultInterface>(undefined);
  const location = useLocation();
  const [totalAmount, setTotalAmount] = React.useState<number>(0);

  React.useEffect(() => {
    if (donationCartResult !== undefined) {
      setTotalAmount(0);
      for (const cart of donationCartResult.data) {
        setTotalAmount((state) => (state += cart.amount));
      }
    }
  }, [donationCartResult]);

  React.useEffect(() => {
    window.onpopstate = () => {
      cancelPayment(donationCartResult?.data[0]?.invoice_code_parent);
    };
  }, [donationCartResult]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (transactionStatusModel !== undefined) {
      if (
        transactionStatusModel?.data?.transaction_status === "settlement" ||
        transactionStatusModel?.data?.transaction_status === "capture"
      ) {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            console.log(response);
            const url = `${response?.data?.platform_donation}/payment/success?order_id=${transactionStatusModel?.data?.order_id}&result=success`;
            window.location.href = url;
          });
      } else if (
        transactionStatusModel?.data?.transaction_status === "cancel" ||
        transactionStatusModel?.data?.transaction_status === "deny"
      ) {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            const url = `${response?.data?.platform_donation}/payment/failed?order_id=${transactionStatusModel?.data?.order_id}&result=failed`;
            window.location.href = url;
          });
      } else if (
        transactionStatusModel?.data?.transaction_status === "expire"
      ) {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            const url = `${response?.data?.platform_donation}/payment/timeout?order_id=${transactionStatusModel?.data?.order_id}&result=expire`;
            window.location.href = url;
          });
      }
    }
  }, [transactionStatusModel]);

  React.useEffect(() => {
    if (donationCartResult !== undefined) {
      const interval = setInterval(() => {
        transactionStatus(donationCartResult?.data[0]?.invoice_code_parent);
      }, 5000);

      // Stop the interval when the component unmounts
      return () => clearInterval(interval);
    }
  }, [donationCartResult]);

  React.useEffect(() => {
    if (location) {
      setChannel((location.state as PaymentInterfaceParam)?.item);
      setDonationCartResultV2(
        (location.state as PaymentInterfaceParam)?.donationCartResult
      );
      setDonationCartResult(
        (location.state as PaymentInterfaceParam)?.donationCartResult
      );
    }
  }, [location]);

  React.useEffect(() => {
    if (channel) {
      // doPayment(channel, encrypt);
      // setVaNumber(channel.account_number);
      if (channel.type !== ChannelType.EWALLET) {
        if (channel.additional_text) {
          if (channel.additional_text.length > 0) {
            setBankCode(channel.additional_text[0]);
          }
        }
      }
    }
  }, [channel]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (donationCartResult !== undefined && channel !== undefined) {
      doPaymentCartV2(channel);
      for (const donation of donationCartResult.data) {
        initPayment(donation?.id, channel?.id, "");
      }
    }
  }, [donationCartResult, channel]);

  React.useEffect(() => {
    if (paymentResult?.status) {
      if (
        paymentResult.data?.permata_va_number !== null &&
        paymentResult.data?.permata_va_number !== undefined
      ) {
        setVaNumber(paymentResult.data?.permata_va_number);
      } else if (paymentResult.data?.payment_type === "cstore") {
        setVaNumber(paymentResult.data.payment_code);
      } else if (paymentResult.data?.payment_type === "echannel") {
        setVaNumber(`${paymentResult.data?.bill_key}`);
        // setVaNumber(`${paymentResult.data?.biller_code}${paymentResult.data?.bill_key}`)
      } else {
        if (paymentResult.data?.va_numbers) {
          if (paymentResult.data?.va_numbers?.length > 0) {
            setVaNumber(paymentResult.data?.va_numbers![0].va_number);
          }
        }
      }

      if (paymentResult.data) {
        if (paymentResult.data.actions) {
          if (paymentResult.data.actions.length > 0) {
            var qr = paymentResult.data.actions.find(
              (item) => item.name === "generate-qr-code"
            );
            if (qr) {
              setQrCode(qr.url);
            }

            var dl = paymentResult.data.actions.find(
              (item) => item.name === "deeplink-redirect"
            );
            if (dl) {
              setDeeplink(dl.url);
            }
          }
        }
      }
    }
  }, [paymentResult]);

  return (
    <div
      style={{
        margin: 20,
      }}
    >
      <Skeleton loading={loading}>
        <h1>{channel?.title}</h1>
        <h2>{channel?.subtitle}</h2>
        {paymentResult?.status ? (
          <>
            <Card
              hoverable
              style={{
                marginBottom: 10,
              }}
            >
              <p className="amount">Jumlah</p>
              <div
                style={{
                  float: "right",
                }}
              >
                <span className="amount-rp">Rp </span>
                <span className="amount-number">
                  {thousandSeparator(totalAmount)}
                </span>
              </div>
            </Card>
            {channel?.type === ChannelType.EWALLET && (
              <Card
                hoverable
                style={{
                  marginBottom: 10,
                  textAlign: "center",
                }}
              >
                <span className="account-number-text">
                  {channel.additional_text ? channel.additional_text[0] : ""}
                </span>
                <br />
                {qrCode !== undefined && (
                  <img
                    alt={qrCode}
                    src={qrCode}
                    style={{
                      textAlign: "center",
                      minWidth: "6.5cm",
                      maxWidth: "6.5cm",
                    }}
                  />
                )}
                <Divider />
                {deeplink !== undefined && (
                  <a
                    className="deeplink"
                    target={"_blank"}
                    rel="noreferrer"
                    href={deeplink}
                  >
                    {channel.additional_text ? channel.additional_text[1] : ""}
                  </a>
                )}

                {qrCode !== undefined ? (
                  <Button
                    type="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      // Replace 'image-url.jpg' with the actual URL or local path of your image
                      fetch(
                        `${process.env.REACT_APP_BASE_URL}/api/payment_downloadqr?qr_code=${qrCode}`,
                        {
                          method: "GET",
                        }
                      )
                        .then((response) => response.blob())
                        .then((blob) => {
                          const url = window.URL.createObjectURL(
                            new Blob([blob])
                          );

                          const link = document.createElement("a");
                          link.href = url;
                          link.download = `qris.jpg`;

                          document.body.appendChild(link);

                          link.click();

                          link.parentNode.removeChild(link);
                        })
                        .catch((e) => {
                          console.log(e);
                        });
                    }}
                  >
                    Download QRIS
                  </Button>
                ) : null}
              </Card>
            )}
            {bankCode && (
              <Card
                hoverable
                style={{
                  marginBottom: 10,
                }}
              >
                <p className="amount">Kode Bank</p>
                <div
                  style={{
                    float: "right",
                  }}
                >
                  <span className="amount-number">{bankCode}</span>
                </div>
              </Card>
            )}
            {channel?.type !== ChannelType.EWALLET ? (
              <Card
                style={{
                  marginBottom: 10,
                }}
              >
                {vaNumber && (
                  <div style={{ float: "left" }}>
                    <span className="account-number-text-block">
                      Nomor Rekening
                    </span>
                    <span className="account-number">{vaNumber}</span>
                    <br />
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        message.info(`Nomor Rekening berhasil di salin.`);
                        navigator.clipboard.writeText(vaNumber);
                      }}
                    >
                      Salin Nomor Rekening
                    </a>
                  </div>
                )}
              </Card>
            ) : (
              <div></div>
            )}
          </>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={paymentResult?.data?.status_message}
          />
        )}
      </Skeleton>

      {donationCartResult?.data?.map((item, index) => (
        <Card
          hoverable
          title={`Detail Transaksi #${index + 1}`}
          style={{ marginBottom: 10 }}
          key={index}
        >
          <span className="transaction-detail-text">Nama Produk</span>
          <span className="transaction-detail-description">
            {item.program_name}
          </span>
        </Card>
      ))}

      {channel?.hows?.length > 0 ? (
        <Card hoverable title={"Cara Pembayaran"} style={{ marginBottom: 10 }}>
          <Tabs>
            {channel?.hows?.map((item, index) => (
              <Tabs.TabPane tab={item.name} key={index}>
                {item.steps?.map((item, index) => (
                  <p key={index}>
                    {index + 1}. {item}
                  </p>
                ))}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Card>
      ) : (
        <></>
      )}
    </div>
  );
}

export default PaymentMidtransCart;
