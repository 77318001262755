import { Card, Divider, Empty, message, Skeleton, Tabs } from "antd";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ChannelType } from "../../contexts/channels/ChannelProvider";
import {
  PaymentContext,
  PaymentContextProvider,
  PaymentContextType,
} from "../../contexts/payment/PaymentContext";
import { thousandSeparator } from "../../helpers/thousand_separator";
import { ChannelModel } from "../../models/ChannelModel";
import { PaymentInterfaceParam } from "../channels/Channel";
import "./payment.css";
import { DonationSingleResultInterface } from "../../contexts/payment/interfaces/donation-single-result.interface";
import axios from "axios";
import { CompanySingleResultInterface } from "../../models/CompanyModel";
import QRCode from "react-qr-code";

function PaymentIPayMu() {
  return (
    <PaymentContextProvider>
      <PaymentState />
    </PaymentContextProvider>
  );
}

function PaymentState() {
  const [channel, setChannel] = React.useState<ChannelModel>();
  const [encrypt, setEncrypt] = React.useState<string>("");
  const {
    paymentResultIpaymu,
    doPaymentIpaymu,
    loading,
    transactionModel,
    cancelPayment,
    initPayment,
    transactionStatus,
    transactionStatusModel,
    setDonationResultV2,
    getDonationDetailForStatus,
    donationDetailForStatus,
  } = React.useContext(PaymentContext) as PaymentContextType;
  const [vaNumber, setVaNumber] = React.useState<string | undefined>(
    "123456789"
  );
  const [qrCode, setQrCode] = React.useState<string>();
  const [deeplink, setDeeplink] = React.useState<string>();
  const [bankCode, setBankCode] = React.useState<string>();
  const [donationResult, setDonationResult] =
    React.useState<DonationSingleResultInterface>(undefined);
  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    if (donationResult) {
      // get donation status every 5 seconds
      const interval = setInterval(() => {
        getDonationDetailForStatus(donationResult?.data?.invoice_code);
      }, 5000);
      // Stop the interval when the component unmounts
      return () => clearInterval(interval);
    }
  }, [donationResult]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (transactionStatusModel !== undefined) {
      if (
        transactionStatusModel?.data?.transaction_status === "settlement" ||
        transactionStatusModel?.data?.transaction_status === "capture"
      ) {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            console.log(response);
            const url = `${response?.data?.platform_donation}/payment/success?order_id=${transactionStatusModel?.data?.order_id}&result=success`;
            window.location.href = url;
          });
      } else if (
        transactionStatusModel?.data?.transaction_status === "cancel" ||
        transactionStatusModel?.data?.transaction_status === "deny"
      ) {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            const url = `${response?.data?.platform_donation}/payment/failed?order_id=${transactionStatusModel?.data?.order_id}&result=failed`;
            window.location.href = url;
          });
      } else if (
        transactionStatusModel?.data?.transaction_status === "expire"
      ) {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            const url = `${response?.data?.platform_donation}/payment/timeout?order_id=${transactionStatusModel?.data?.order_id}&result=expire`;
            window.location.href = url;
          });
      }
    }
  }, [transactionStatusModel]);

  React.useEffect(() => {
    if (paymentResultIpaymu !== undefined) {
      // const interval = setInterval(() => {
      //   transactionStatus(paymentResult?.data?.order_id);
      // }, 5000);
      // Stop the interval when the component unmounts
      // return () => clearInterval(interval);
    }
  }, [paymentResultIpaymu]);

  React.useEffect(() => {
    if (location) {
      setChannel((location.state as PaymentInterfaceParam)?.item);
      setEncrypt((location.state as PaymentInterfaceParam)?.encrypt);
      setDonationResult(
        (location.state as PaymentInterfaceParam)?.donationResult
      );
      setDonationResultV2(
        (location.state as PaymentInterfaceParam)?.donationResult
      );
    }
  }, [location]);

  React.useEffect(() => {
    if (channel) {
      // doPayment(channel, encrypt);
      // setVaNumber(channel.account_number);
      if (channel.type !== ChannelType.EWALLET) {
        if (channel.additional_text) {
          if (channel.additional_text.length > 0) {
            setBankCode(channel.additional_text[0]);
          }
        }
      }
    }
  }, [channel]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (donationResult !== undefined && channel !== undefined) {
      doPaymentIpaymu(channel);
      initPayment(donationResult?.data?.id, channel?.id, "");
    }
  }, [donationResult, channel]);

  React.useEffect(() => {
    if (paymentResultIpaymu?.status) {
      setVaNumber(paymentResultIpaymu?.data?.Data?.PaymentNo);
      setQrCode(paymentResultIpaymu?.data?.Data?.QrImage);
      // if (
      //   paymentResult.data?.permata_va_number !== null &&
      //   paymentResult.data?.permata_va_number !== undefined
      // ) {
      //   setVaNumber(paymentResult.data?.permata_va_number);
      // } else if (paymentResult.data?.payment_type === "cstore") {
      //   setVaNumber(paymentResult.data.payment_code);
      // } else if (paymentResult.data?.payment_type === "echannel") {
      //   setVaNumber(`${paymentResult.data?.bill_key}`);
      //   // setVaNumber(`${paymentResult.data?.biller_code}${paymentResult.data?.bill_key}`)
      // } else {
      //   if (paymentResult.data?.va_numbers) {
      //     if (paymentResult.data?.va_numbers?.length > 0) {
      //       setVaNumber(paymentResult.data?.va_numbers![0].va_number);
      //     }
      //   }
      // }
      // if (paymentResult.data) {
      //   if (paymentResult.data.actions) {
      //     if (paymentResult.data.actions.length > 0) {
      //       var qr = paymentResult.data.actions.find(
      //         (item) => item.name === "generate-qr-code"
      //       );
      //       if (qr) {
      //         setQrCode(qr.url);
      //       }
      //       var dl = paymentResult.data.actions.find(
      //         (item) => item.name === "deeplink-redirect"
      //       );
      //       if (dl) {
      //         setDeeplink(dl.url);
      //       }
      //     }
      //   }
      // }
    }
  }, [paymentResultIpaymu]);

  React.useEffect(() => {
    if (donationDetailForStatus !== undefined) {
      if (
        donationDetailForStatus?.data?.status === "settlement" ||
        donationDetailForStatus?.data?.status === "capture"
      ) {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            console.log(response);
            const url = `${response?.data?.platform_donation}/payment/success?order_id=${donationDetailForStatus?.data?.order_id}&result=success`;
            window.location.href = url;
          });
      } else if (
        donationDetailForStatus?.data?.status === "cancel" ||
        donationDetailForStatus?.data?.status === "deny"
      ) {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            const url = `${response?.data?.platform_donation}/payment/failed?order_id=${donationDetailForStatus?.data?.order_id}&result=failed`;
            window.location.href = url;
          });
      } else if (donationDetailForStatus?.data?.status === "expire") {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            const url = `${response?.data?.platform_donation}/payment/timeout?order_id=${donationDetailForStatus?.data?.order_id}&result=expire`;
            window.location.href = url;
          });
      }
    }
  }, [donationDetailForStatus]);

  return (
    <div
      style={{
        margin: 20,
      }}
    >
      <Skeleton loading={loading}>
        <h1>{channel?.title}</h1>
        <h2>{channel?.subtitle}</h2>
        {paymentResultIpaymu?.status &&
        paymentResultIpaymu?.data?.Status === 200 ? (
          <>
            <Card
              hoverable
              style={{
                marginBottom: 10,
              }}
            >
              <p className="amount">Jumlah</p>
              <div
                style={{
                  float: "right",
                }}
              >
                <span className="amount-rp">Rp </span>
                <span className="amount-number">
                  {thousandSeparator(donationResult?.data?.amount)}
                </span>
              </div>
            </Card>
            {channel?.type === ChannelType.EWALLET && (
              <Card
                hoverable
                style={{
                  marginBottom: 10,
                  textAlign: "center",
                }}
              >
                <span className="account-number-text">
                  {channel.additional_text ? channel.additional_text[0] : ""}
                </span>
                <br />
                {qrCode !== undefined ? (
                  <div>
                    <QRCode value={paymentResultIpaymu?.data?.Data?.QrString} />
                    <Divider />
                    <img
                      src="/assets/qris-bpd-bali.png"
                      style={{ width: "13em" }}
                      alt="QRIS iPaymu"
                    />
                    <br />
                    <img
                      src="/assets/scan_qris.png"
                      style={{ width: "15em" }}
                      alt="QRIS iPaymu"
                    />
                  </div>
                ) : null}
                {deeplink !== undefined && (
                  <a
                    className="deeplink"
                    target={"_blank"}
                    rel="noreferrer"
                    href={deeplink}
                  >
                    {channel.additional_text
                      ? channel.additional_text[1]
                      : "Error"}
                  </a>
                )}
              </Card>
            )}
            {bankCode && (
              <Card
                hoverable
                style={{
                  marginBottom: 10,
                }}
              >
                <p className="amount">Kode Bank</p>
                <div
                  style={{
                    float: "right",
                  }}
                >
                  <span className="amount-number">{bankCode}</span>
                </div>
              </Card>
            )}
            {channel?.type !== ChannelType.EWALLET ? (
              <Card
                style={{
                  marginBottom: 10,
                }}
              >
                {vaNumber && (
                  <div style={{ float: "left" }}>
                    <span className="account-number-text-block">
                      Nomor Rekening
                    </span>
                    <span className="account-number">{vaNumber}</span>
                    <br />
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        message.info(`Nomor Rekening berhasil di salin.`);
                        navigator.clipboard.writeText(vaNumber);
                      }}
                    >
                      Salin Nomor Rekening
                    </a>
                  </div>
                )}
              </Card>
            ) : (
              <div></div>
            )}
          </>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={paymentResultIpaymu?.data?.Message}
          />
        )}
      </Skeleton>

      <Card hoverable title={"Detail Transaksi"} style={{ marginBottom: 10 }}>
        <span className="transaction-detail-text">Nama Program</span>
        <span className="transaction-detail-description">
          {donationResult?.data?.program_name}
        </span>
      </Card>

      {channel?.hows?.length > 0 ? (
        <Card hoverable title={"Cara Pembayaran"} style={{ marginBottom: 10 }}>
          <Tabs>
            {channel?.hows?.map((item, index) => (
              <Tabs.TabPane tab={item.name} key={index}>
                {item.steps?.map((item, index) => (
                  <p key={index}>
                    {index + 1}. {item}
                  </p>
                ))}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Card>
      ) : (
        <></>
      )}
    </div>
  );
}

export default PaymentIPayMu;
