import Axios, { AxiosInstance } from "axios";

export default class Api {
  public baseUrl?: string;
  public programApiUrl?: string;
  public walletApiURL?: string;
  public marketplaceApiURL?: string;
  public uploadFileURL?: string;
  public uploadFileKey?: string;
  public api: AxiosInstance;
  public apiProgram: AxiosInstance;
  public apiWallet: AxiosInstance;
  public apiMarketplace: AxiosInstance;
  public apiUploadFile: AxiosInstance;

  constructor() {
    this.baseUrl = process.env.REACT_APP_BASE_URL;
    this.programApiUrl = process.env.REACT_APP_PROGRAM_API_URL;
    this.walletApiURL = process.env.REACT_APP_WALLET_API_URL;
    this.marketplaceApiURL = process.env.REACT_APP_MARKETPLACE_API_URL;
    this.uploadFileURL = process.env.REACT_APP_STORAGE_URL;
    this.uploadFileKey = process.env.REACT_APP_STORAGE_KEY;

    this.api = Axios.create({
      baseURL: this.baseUrl,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    this.apiProgram = Axios.create({
      baseURL: this.programApiUrl,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    this.apiWallet = Axios.create({
      baseURL: this.walletApiURL,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    this.apiMarketplace = Axios.create({
      baseURL: this.marketplaceApiURL,
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    this.apiUploadFile = Axios.create({
      baseURL: this.uploadFileURL,
      responseType: "json",
      headers: {
        "Content-Type": "multipart/form-data",
        "storage-api-key": this.uploadFileKey,
      },
    });
  }
}
