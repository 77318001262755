import { Card, message, Skeleton, Tabs } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import { ChannelType } from "../../contexts/channels/ChannelProvider";
import {
  PaymentContext,
  PaymentContextProvider,
  PaymentContextType,
} from "../../contexts/payment/PaymentContext";
import { thousandSeparator } from "../../helpers/thousand_separator";
import { ChannelModel } from "../../models/ChannelModel";
import "./payment.css";
import { DonationSingleResultInterface } from "../../contexts/payment/interfaces/donation-single-result.interface";
import axios from "axios";
import { CompanySingleResultInterface } from "../../models/CompanyModel";

function PaymentV2() {
  return (
    <PaymentContextProvider>
      <PaymentState />
    </PaymentContextProvider>
  );
}

function PaymentState() {
  const [channel, setChannel] = React.useState<ChannelModel>();
  const {
    initPayment,
    getDonationDetail,
    donationDetail,
    getDonationDetailForStatus,
    donationDetailForStatus,
    donationDetailLoading,
  } = React.useContext(PaymentContext) as PaymentContextType;
  const [vaNumber, setVaNumber] = React.useState<string | undefined>(
    "123456789"
  );
  const [bankCode, setBankCode] = React.useState<string>();
  const [donationResult, setDonationResult] =
    React.useState<DonationSingleResultInterface>(undefined);
  const params = useParams();

  React.useEffect(() => {
    if (params) {
      if (params["invoice_code"] !== undefined) {
        getDonationDetail(params["invoice_code"] as string);

        const interval = setInterval(() => {
          getDonationDetailForStatus(params["invoice_code"] as string);
        }, 5000);
        // Stop the interval when the component unmounts
        return () => clearInterval(interval);
      }
    }
  }, [params]);

  React.useEffect(() => {
    if (donationDetail !== undefined) {
      setChannel({
        id: donationDetail?.data?.payment_id,
        thumbnail: `${donationDetail?.data?.payment_name?.toLowerCase()} sprite`,
        type: ChannelType.MANUAL_BANK_TRANSFER,
        payment_type: "manual_bank_transfer",
        code: donationDetail?.data?.payment_name?.toLowerCase(),
        title: donationDetail?.data?.payment_name,
        subtitle: donationDetail?.data?.payment_description,
        hows: [
          {
            name: "Transfer Manual",
            steps: [
              "Pilih Transfer pada menu utama bank pilihan Anda. Transfer bisa dilakukan melalui ATM, SMS Banking, atau Internet Banking.",
              "Masukkan nomor rekening di atas. Kemudian, masukkan nominal sesuai dengan jumlah yang tertera pada nominal diatas.",
            ],
          },
        ],
        account_number: donationDetail?.data?.payment_account,
      });
      setDonationResult(donationDetail);
    }
  }, [donationDetail]);

  React.useEffect(() => {
    if (channel) {
      // doPayment(channel, encrypt);
      setVaNumber(channel.account_number);
      if (channel.type !== ChannelType.EWALLET) {
        if (channel.additional_text) {
          if (channel.additional_text.length > 0) {
            setBankCode(channel.additional_text[0]);
          }
        }
      }
    }
  }, [channel]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (donationResult !== undefined && channel !== undefined) {
      console.log(donationResult);
      initPayment(donationResult?.data?.id, channel?.id, "");
    }
  }, [donationResult, channel]);

  React.useEffect(() => {
    if (donationDetailForStatus !== undefined) {
      if (
        donationDetailForStatus?.data?.status === "settlement" ||
        donationDetailForStatus?.data?.status === "capture"
      ) {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            console.log(response);
            const url = `${response?.data?.platform_donation}/payment/success?order_id=${donationDetailForStatus?.data?.order_id}&result=success`;
            window.location.href = url;
          });
      } else if (
        donationDetailForStatus?.data?.status === "cancel" ||
        donationDetailForStatus?.data?.status === "deny"
      ) {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            const url = `${response?.data?.platform_donation}/payment/failed?order_id=${donationDetailForStatus?.data?.order_id}&result=failed`;
            window.location.href = url;
          });
      } else if (donationDetailForStatus?.data?.status === "expire") {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            const url = `${response?.data?.platform_donation}/payment/timeout?order_id=${donationDetailForStatus?.data?.order_id}&result=expire`;
            window.location.href = url;
          });
      }
    }
  }, [donationDetailForStatus]);

  return (
    <>
      <div style={{ margin: 20 }}>
        <Skeleton loading={donationDetailLoading}>
          <h1>{channel?.title}</h1>
          <h1>{channel?.subtitle}</h1>
          {channel?.type === ChannelType.MANUAL_BANK_TRANSFER ? (
            <>
              <Card
                hoverable
                style={{
                  marginBottom: 10,
                }}
              >
                <p className="amount">Jumlah</p>
                <div
                  style={{
                    float: "right",
                  }}
                >
                  <span className="amount-rp">Rp </span>
                  <span className="amount-number">
                    {thousandSeparator(
                      donationResult?.data?.amount +
                        (donationResult?.data?.unique_code ?? 0)
                    )}
                  </span>
                </div>
              </Card>
              <Card
                style={{
                  marginBottom: 10,
                }}
              >
                {vaNumber && (
                  <div style={{ float: "left" }}>
                    <span className="account-number-text-block">
                      Nomor Rekening
                    </span>
                    <span className="account-number">{vaNumber}</span>
                    <br />
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        message.info(`Nomor Rekening berhasil di salin.`);
                        navigator.clipboard.writeText(vaNumber);
                      }}
                    >
                      Salin Nomor Rekening
                    </a>
                  </div>
                )}
              </Card>
            </>
          ) : (
            <></>
          )}
          <Card hoverable title={"Perhatian"} style={{ marginBottom: 10 }}>
            {/* <span className="transaction-detail-text"></span> */}
            <span
              className="transaction-detail-description"
              style={{
                color: "#F0416C",
              }}
            >
              <b>
                Silakan transfer sesuai nominal yang tercantum di atas. Tiga
                digit kode unik akan didonasikan untuk campaign terkait.
              </b>
            </span>
          </Card>
          <Card
            hoverable
            title={"Detail Transaksi"}
            style={{ marginBottom: 10 }}
          >
            <span className="transaction-detail-text">Nama Program</span>
            <span className="transaction-detail-description">
              {donationResult?.data?.program_name}
            </span>
          </Card>
          {channel?.hows?.length > 0 ? (
            <Card
              hoverable
              title={"Cara Pembayaran"}
              style={{ marginBottom: 10 }}
            >
              <Tabs>
                {channel?.hows?.map((item, index) => (
                  <Tabs.TabPane tab={item.name} key={index}>
                    {item.steps?.map((item, index) => (
                      <p key={index}>
                        {index + 1}. {item}
                      </p>
                    ))}
                  </Tabs.TabPane>
                ))}
              </Tabs>
            </Card>
          ) : (
            <></>
          )}
        </Skeleton>
      </div>
    </>
  );
}

export default PaymentV2;
