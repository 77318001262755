import { useEffect } from "react";
import { useAuthStore } from "../contexts/auth/auth.state";

const TrackingCodeHeaderComponent = () => {
  const { companyData } = useAuthStore();

  useEffect(() => {
    if (companyData !== null || companyData !== undefined) {
      if (companyData?.status === true) {
        const script = document.createElement("script");
        script.innerHTML = `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${companyData?.data?.google_tag_manager_id}');
        `;
        document?.head?.appendChild(script);
      }
    }
  }, [companyData]);

  return null;
};

export default TrackingCodeHeaderComponent;
