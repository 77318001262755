import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  PaymentContext,
  PaymentContextProvider,
  PaymentContextType,
} from "../../contexts/payment/PaymentContext";
import { ChannelModel } from "../../models/ChannelModel";
import { ChannelType } from "../../contexts/channels/ChannelProvider";
import { Card, Tabs, message } from "antd";
import { thousandSeparator } from "../../helpers/thousand_separator";
import axios from "axios";
import { CompanySingleResultInterface } from "../../models/CompanyModel";

const PaymentTopupV2 = () => {
  return (
    <PaymentContextProvider>
      <PaymentTopupV2State />
    </PaymentContextProvider>
  );
};

const PaymentTopupV2State = () => {
  const {
    walletDetail,
    getWalletDetail,
    getWalletDetailStatus,
    walletDetailStatus,
  } = useContext<PaymentContextType>(PaymentContext);
  const [channel, setChannel] = useState<ChannelModel>();
  const [vaNumber, setVaNumber] = useState<string | undefined>(
    "Error fetching va number"
  );
  const params = useParams();

  useEffect(() => {
    if (params) {
      if (params["invoice_code"] !== undefined) {
        getWalletDetail(params["invoice_code"] as string);

        const interval = setInterval(() => {
          getWalletDetailStatus(params["invoice_code"] as string);
        }, 5000);
        // Stop the interval when the component unmounts
        return () => clearInterval(interval);
      }
    }
  }, []);

  useEffect(() => {
    if (walletDetail !== undefined) {
      setChannel({
        id: walletDetail?.data?.payment_id,
        thumbnail: `${walletDetail?.data?.payment_name?.toLowerCase()} sprite`,
        type: ChannelType.MANUAL_BANK_TRANSFER,
        payment_type: "manual_bank_transfer",
        code: walletDetail?.data?.payment_name?.toLowerCase(),
        title: walletDetail?.data?.payment_name,
        subtitle: walletDetail?.data?.payment_description,
        hows: [
          {
            name: "Transfer Manual",
            steps: [
              "Pilih Transfer pada menu utama bank pilihan Anda. Transfer bisa dilakukan melalui ATM, SMS Banking, atau Internet Banking.",
              "Masukkan nomor rekening di atas. Kemudian, masukkan nominal sesuai dengan jumlah yang tertera pada nominal diatas.",
            ],
          },
        ],
        account_number: walletDetail?.data?.payment_account,
      });
    }
  }, [walletDetail]);

  useEffect(() => {
    if (channel) {
      setVaNumber(channel?.account_number);
    }
  }, [channel]);

  useEffect(() => {
    if (walletDetailStatus !== undefined) {
      if (walletDetailStatus?.data?.status === "settlement") {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            console.log(response);
            const url = `${response?.data?.platform_donation}/payment/success-topup?invoice_code=${walletDetailStatus?.data?.invoice_code}&result=success`;
            window.location.href = url;
          });
      } else if (
        walletDetailStatus?.data?.status === "cancel" ||
        walletDetailStatus?.data?.status === "deny"
      ) {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            const url = `${response?.data?.platform_donation}/payment/failed-topup?invoice_code=${walletDetailStatus?.data?.invoice_code}&result=failed`;
            window.location.href = url;
          });
      } else if (walletDetailStatus?.data?.status === "expired") {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            const url = `${response?.data?.platform_donation}/payment/timeout-topup?invoice_code=${walletDetailStatus?.data?.invoice_code}&result=expire`;
            window.location.href = url;
          });
      }
    }
  }, [walletDetailStatus]);

  return (
    <>
      <div style={{ margin: 20 }}>
        <h1>{channel?.title}</h1>
        <h1>{channel?.subtitle}</h1>
        {channel?.type === ChannelType.MANUAL_BANK_TRANSFER ? (
          <>
            <Card
              hoverable
              style={{
                marginBottom: 10,
              }}
            >
              <p className="amount">Jumlah</p>
              <div
                style={{
                  float: "right",
                }}
              >
                <span className="amount-rp">Rp </span>
                <span className="amount-number">
                  {thousandSeparator(walletDetail?.data?.amount)}
                </span>
              </div>
            </Card>
            <Card
              style={{
                marginBottom: 10,
              }}
            >
              {vaNumber && (
                <div style={{ float: "left" }}>
                  <span className="account-number-text-block">
                    Nomor Rekening
                  </span>
                  <span className="account-number">{vaNumber}</span>
                  <br />
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      message.info(`Nomor Rekening berhasil di salin.`);
                      navigator.clipboard.writeText(vaNumber);
                    }}
                  >
                    Salin Nomor Rekening
                  </a>
                </div>
              )}
            </Card>
          </>
        ) : (
          <></>
        )}
        <Card hoverable title={"Perhatian"} style={{ marginBottom: 10 }}>
          {/* <span className="transaction-detail-text"></span> */}
          <span
            className="transaction-detail-description"
            style={{
              color: "#F0416C",
            }}
          >
            <b>Silakan transfer sesuai nominal yang tercantum di atas.</b>
          </span>
        </Card>
        <Card hoverable title={"Detail Transaksi"} style={{ marginBottom: 10 }}>
          <span className="transaction-detail-text">Nama Transaksi</span>
          <span className="transaction-detail-description">Topup Wallet</span>
        </Card>
        {channel?.hows?.length > 0 ? (
          <Card
            hoverable
            title={"Cara Pembayaran"}
            style={{ marginBottom: 10 }}
          >
            <Tabs>
              {channel?.hows?.map((item, index) => (
                <Tabs.TabPane tab={item.name} key={index}>
                  {item.steps?.map((item, index) => (
                    <p key={index}>
                      {index + 1}. {item}
                    </p>
                  ))}
                </Tabs.TabPane>
              ))}
            </Tabs>
          </Card>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default PaymentTopupV2;
