import { useContext, useEffect, useState } from "react";
import {
  PaymentContext,
  PaymentContextProvider,
  PaymentContextType,
} from "../../contexts/payment/PaymentContext";
import { ChannelModel } from "../../models/ChannelModel";
import { useParams } from "react-router-dom";
import { ChannelType } from "../../contexts/channels/ChannelProvider";
import { DonationSingleResultInterface } from "../../contexts/payment/interfaces/donation-single-result.interface";
import { Button, Card, Spin, Tabs, message } from "antd";
import { thousandSeparator } from "../../helpers/thousand_separator";
import axios from "axios";
import { CompanySingleResultInterface } from "../../models/CompanyModel";

const PaymentWalletV2 = () => {
  return (
    <PaymentContextProvider>
      <PaymentWalletV2State />
    </PaymentContextProvider>
  );
};

const PaymentWalletV2State = () => {
  const [channel, setChannel] = useState<ChannelModel>();
  const {
    paymentResult,
    doPayment,
    loading,
    transactionModel,
    cancelPayment,
    initPayment,
    getDonationDetail,
    donationDetail,
    getDonationDetailForStatus,
    donationDetailForStatus,
    getBalanceWalletUser,
    balanceWallet,
    deductBalanceResult,
    doDeductBalance,
    deductLoading,
  } = useContext(PaymentContext) as PaymentContextType;
  const params = useParams();
  const [donationResult, setDonationResult] =
    useState<DonationSingleResultInterface>(undefined);

  useEffect(() => {
    if (params) {
      if (params["invoice_code"] !== undefined) {
        getDonationDetail(params["invoice_code"] as string);

        const interval = setInterval(() => {
          getDonationDetailForStatus(params["invoice_code"] as string);
        }, 5000);
        // Stop the interval when the component unmounts
        return () => clearInterval(interval);
      }
    }
  }, [params]);

  useEffect(() => {
    if (donationResult !== undefined && channel !== undefined) {
      initPayment(donationResult?.data?.id, channel?.id, "");
    }
  }, [donationResult, channel]);

  useEffect(() => {
    if (donationDetail !== undefined) {
      setChannel({
        id: donationDetail?.data?.payment_id,
        thumbnail: `${donationDetail?.data?.payment_name?.toLowerCase()} sprite`,
        type: ChannelType.UNIVERSA_WALLET,
        payment_type: "wallet",
        code: donationDetail?.data?.payment_name?.toLowerCase(),
        title: donationDetail?.data?.payment_name,
        subtitle: "Pembayaran menggunakan Wallet",
        hows: [
          {
            name: "Wallet",
            steps: ["Dipotong langsung dari saldo wallet anda"],
          },
        ],
        account_number: donationDetail?.data?.payment_account,
      });
      setDonationResult(donationDetail);
      getBalanceWalletUser(donationDetail?.data?.created_by);
    }
  }, [donationDetail]);

  useEffect(() => {
    if (deductBalanceResult) {
      if (deductBalanceResult?.status === true) {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            console.log(response);
            const url = `${response?.data?.platform_donation}/payment/success?order_id=${donationDetail?.data?.order_id}&result=success`;
            window.location.href = url;
          });
      }
    }
  }, [deductBalanceResult]);

  return (
    <>
      <div style={{ margin: 20 }}>
        <h1>{channel?.title}</h1>
        <h1>{channel?.subtitle}</h1>
        <>
          <Card
            hoverable
            style={{
              marginBottom: 10,
            }}
          >
            <p className="balance">Saldo Wallet</p>
            <div
              style={{
                float: "right",
              }}
            >
              <span className="balance-rp">Rp </span>
              <span className="balance-number">
                {thousandSeparator(balanceWallet?.data)}
              </span>
            </div>
          </Card>
          <Card
            hoverable
            style={{
              marginBottom: 10,
            }}
          >
            <p className="amount">Jumlah</p>
            <div
              style={{
                float: "right",
              }}
            >
              <span className="amount-rp">Rp </span>
              <span className="amount-number">
                {thousandSeparator(donationResult?.data?.amount)}
              </span>
            </div>
          </Card>
          {balanceWallet?.data - donationResult?.data?.amount > 0 ? (
            <>
              <Card
                hoverable
                style={{
                  marginBottom: 10,
                }}
              >
                <p className="balance-left">Sisa saldo Wallet</p>
                <div
                  style={{
                    float: "right",
                  }}
                >
                  <span className="balance-left-rp">Rp </span>
                  <span className="balance-left-number">
                    {thousandSeparator(
                      balanceWallet?.data - donationResult?.data?.amount
                    )}
                  </span>
                </div>
              </Card>
              <Spin tip="Loading..." spinning={deductLoading}>
                <Card
                  hoverable
                  style={{
                    marginBottom: 10,
                    backgroundColor: "#4078f2",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    doDeductBalance(
                      donationDetail?.data?.created_by,
                      donationDetail?.data?.id,
                      donationDetail?.data?.amount,
                      `Pembayaran Untuk: ${donationDetail?.data?.program_name}`
                    );
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                        fontWeight: 800,
                        fontSize: "1.5rem",
                      }}
                    >
                      Bayar
                    </span>
                  </div>
                </Card>
              </Spin>
            </>
          ) : (
            <Card hoverable title={"Perhatian"} style={{ marginBottom: 10 }}>
              {/* <span className="transaction-detail-text"></span> */}
              <span
                className="transaction-detail-description"
                style={{
                  color: "#F0416C",
                }}
              >
                <b>
                  Saldo wallet anda tidak cukup, silahkan topup terlebih dahulu
                </b>
                <br />
                <Button type="primary">Topup</Button>
              </span>
            </Card>
          )}
          <Card
            hoverable
            title={"Detail Transaksi"}
            style={{ marginBottom: 10 }}
          >
            <span className="transaction-detail-text">Nama Program</span>
            <span className="transaction-detail-description">
              {donationResult?.data?.program_name}
            </span>
          </Card>
          {channel?.hows?.length > 0 ? (
            <Card
              hoverable
              title={"Cara Pembayaran"}
              style={{ marginBottom: 10 }}
            >
              <Tabs>
                {channel?.hows?.map((item, index) => (
                  <Tabs.TabPane tab={item.name} key={index}>
                    {item.steps?.map((item, index) => (
                      <p key={index}>
                        {index + 1}. {item}
                      </p>
                    ))}
                  </Tabs.TabPane>
                ))}
              </Tabs>
            </Card>
          ) : (
            <></>
          )}
        </>
      </div>
    </>
  );
};

export default PaymentWalletV2;
