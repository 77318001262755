import { useEffect, useState } from "react";
import { useAuthStore } from "../contexts/auth/auth.state";

const TrackingCodeFooterComponent = () => {
  const { companyData } = useAuthStore();
  const [gtmId, setGtmId] = useState<string>();

  useEffect(() => {
    if (companyData !== null || companyData !== undefined) {
      if (companyData?.status === true) {
        setGtmId(companyData?.data?.google_tag_manager_id);
      }
    }
  }, [companyData]);

  return (
    <>
      <noscript>
        <iframe
          title="gtm_id"
          src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
    </>
  );
};

export default TrackingCodeFooterComponent;
