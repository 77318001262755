import { create } from "zustand";
import { CompanySingleResultInterface } from "../../models/CompanyModel";
import axios from "axios";

export interface AuthState {
  getCompany: () => void;
  companyData?: CompanySingleResultInterface;
}

export const useAuthStore = create<AuthState>((set, get) => ({
  companyData: undefined,
  getCompany: async () => {
    const company = await axios.get(
      `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
    );
    const response = company.data as CompanySingleResultInterface;
    set({
      companyData: response,
    });
  },
}));
