import { Button, Card, Divider, Empty, message, Skeleton, Tabs } from "antd";
import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import { ChannelType } from "../../contexts/channels/ChannelProvider";
import {
  PaymentContext,
  PaymentContextProvider,
  PaymentContextType,
} from "../../contexts/payment/PaymentContext";
import { thousandSeparator } from "../../helpers/thousand_separator";
import { ChannelModel } from "../../models/ChannelModel";
import "./payment.css";
import axios from "axios";
import { CompanySingleResultInterface } from "../../models/CompanyModel";
import {
  ALFAMARTMidtrans,
  BANKOTHERVAMidtrans,
  BCAVAMidtrans,
  BNIVAMidtrans,
  BRIVAMidtrans,
  GOPAYMidtrans,
  MANDIRIVAMidtrans,
  PERTAMAVAMidtrans,
  QRISMidtrans,
  SHOPEEMidtrans,
} from "../../contexts/channels/interfaces/midtrans-channel";

function PaymentMidtransCommerce() {
  return (
    <PaymentContextProvider>
      <PaymentState />
    </PaymentContextProvider>
  );
}

function PaymentState() {
  const imageRef = useRef<HTMLImageElement>(null);
  const [channel, setChannel] = React.useState<ChannelModel>(undefined);
  const [encrypt, setEncrypt] = React.useState<string>("");
  const {
    transactionDetailLoading,
    transactionDetail,
    getTransactionDetail,
    doPaymentCommerce,
    paymentResult,
    transactionStatusCommerce,
    getTransactionDetailForStatus,
    transactionDetailForStatus,
  } = React.useContext(PaymentContext) as PaymentContextType;

  const [vaNumber, setVaNumber] = React.useState<string | undefined>("");
  const [qrCode, setQrCode] = React.useState<string>();
  const [deeplink, setDeeplink] = React.useState<string>();
  const [bankCode, setBankCode] = React.useState<string>();
  const params = useParams();
  // getTransactionDetail(params["invoice_code"] as string);

  // React.useEffect(() => {
  //   if (donationResult !== undefined && channel !== undefined) {
  //     if (donationResult?.status === true && channel?.id !== "") {
  //       if (vaNumber !== "") {
  //         initPayment(donationResult?.data?.id, channel?.id, vaNumber);
  //       }
  //     }
  //   }
  // }, [vaNumber, donationResult, channel]);

  // React.useEffect(() => {
  //   if (donationResult !== undefined) {
  //     if (donationResult?.status === true) {
  //       if (donationResult?.data?.va_number !== "") {
  //         setVaNumber(donationResult?.data?.va_number);
  //       }
  //     }
  //   }
  // }, [donationResult]);

  React.useEffect(() => {
    console.log(params);
    if (params) {
      if (params["invoice_code"] !== undefined) {
        getTransactionDetail(params["invoice_code"] as string);
      }
    }
  }, []);

  React.useEffect(() => {
    if (transactionDetail !== undefined) {
      let broChannel: ChannelModel = undefined;
      switch (transactionDetail?.data?.payment_name?.toLowerCase()) {
        case "bca virtual account":
          broChannel = {
            id: transactionDetail?.data?.payment_id,
            ...BCAVAMidtrans,
          };
          break;
        case "mandiri virtual account":
          broChannel = {
            id: transactionDetail?.data?.payment_id,
            ...MANDIRIVAMidtrans,
          };
          break;
        case "bni virtual account":
          broChannel = {
            id: transactionDetail?.data?.payment_id,
            ...BNIVAMidtrans,
          };
          break;
        case "bri virtual account":
          broChannel = {
            id: transactionDetail?.data?.payment_id,
            ...BRIVAMidtrans,
          };
          break;
        case "permata virtual account":
          broChannel = {
            id: transactionDetail?.data?.payment_id,
            ...PERTAMAVAMidtrans,
          };
          break;
        case "bank lainnya":
          broChannel = {
            id: transactionDetail?.data?.payment_id,
            ...BANKOTHERVAMidtrans,
          };
          break;
        case "gopay":
          broChannel = {
            id: transactionDetail?.data?.payment_id,
            ...GOPAYMidtrans,
          };
          break;
        case "shopee":
          broChannel = {
            id: transactionDetail?.data?.payment_id,
            ...SHOPEEMidtrans,
          };
          break;
        case "alfamart":
          broChannel = {
            id: transactionDetail?.data?.payment_id,
            ...ALFAMARTMidtrans,
          };
          break;
        case "qris":
          broChannel = {
            id: transactionDetail?.data?.payment_id,
            ...QRISMidtrans,
          };
          break;
      }
      console.log("ini kepanggil bro", broChannel);
      setChannel(broChannel);
      doPaymentCommerce(broChannel);
      // setDonationResult(transactionDetail);
    }
  }, [transactionDetail]);

  React.useEffect(() => {
    if (transactionDetailForStatus !== undefined) {
      if (
        transactionDetailForStatus?.data?.status === "settlement" ||
        transactionDetailForStatus?.data?.status === "capture"
      ) {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            console.log(response);
            const url = `${response?.data?.platform_donation}/carts/checkout/success?order_id=${transactionDetailForStatus?.data?.invoice_code}&result=success`;
            window.location.href = url;
          });
      } else if (
        transactionDetailForStatus?.data?.status === "cancel" ||
        transactionDetailForStatus?.data?.status === "deny"
      ) {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            const url = `${response?.data?.platform_donation}/carts/checkout/failed?order_id=${transactionDetailForStatus?.data?.invoice_code}&result=failed`;
            window.location.href = url;
          });
      } else if (transactionDetailForStatus?.data?.status === "expire") {
        axios
          .get(
            `${process.env.REACT_APP_MAIN_URL}/public/companies/${process.env.REACT_APP_COMPANY_ID}`
          )
          .then((res) => {
            const response = res.data as CompanySingleResultInterface;
            const url = `${response?.data?.platform_donation}/carts/checkout/timeout?order_id=${transactionDetailForStatus?.data?.invoice_code}&result=expire`;
            window.location.href = url;
          });
      }
    }
  }, [transactionDetailForStatus]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      getTransactionDetailForStatus(params["invoice_code"] as string);
    }, 5000);

    // Stop the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  // React.useEffect(() => {
  //   if (location) {
  //     setChannel((location.state as PaymentInterfaceParam)?.item);
  //     setEncrypt((location.state as PaymentInterfaceParam)?.encrypt);
  //     setDonationResult(JSON.parse(localStorage.getItem("donation_result")));
  //   }
  // }, [location]);

  React.useEffect(() => {
    if (channel) {
      // doPayment(channel, encrypt);
      // setVaNumber(channel.account_number);
      if (channel.type !== ChannelType.EWALLET) {
        if (channel.additional_text) {
          if (channel.additional_text.length > 0) {
            setBankCode(channel.additional_text[0]);
          }
        }
      }
    }
  }, [channel]); // eslint-disable-line react-hooks/exhaustive-deps

  // React.useEffect(() => {
  //   if (donationResult !== undefined && channel !== undefined) {
  //     console.log(donationResult);
  //     if (donationResult?.status === true && channel?.id !== "") {
  //       setCounter((state) => state++);
  //       console.log("bah kepanggil lagi bro");
  //       doPaymentV2(channel);
  //       initPayment(donationResult?.data?.id, channel?.id, "");
  //     }
  //   }
  // }, [donationResult, channel]);

  React.useEffect(() => {
    if (
      paymentResult?.status === true &&
      paymentResult?.data?.status_code !== "406"
    ) {
      if (
        paymentResult.data?.permata_va_number !== null &&
        paymentResult.data?.permata_va_number !== undefined
      ) {
        setVaNumber(paymentResult.data?.permata_va_number);
      } else if (paymentResult.data?.payment_type === "cstore") {
        setVaNumber(paymentResult.data.payment_code);
      } else if (paymentResult.data?.payment_type === "echannel") {
        setVaNumber(`${paymentResult.data?.bill_key}`);
        // setVaNumber(`${paymentResult.data?.biller_code}${paymentResult.data?.bill_key}`)
      } else {
        if (paymentResult.data?.va_numbers) {
          if (paymentResult.data?.va_numbers?.length > 0) {
            setVaNumber(paymentResult.data?.va_numbers![0].va_number);
          }
        }
      }

      if (paymentResult.data) {
        if (paymentResult.data.actions) {
          if (paymentResult.data.actions.length > 0) {
            var qr = paymentResult.data.actions.find(
              (item) => item.name === "generate-qr-code"
            );
            if (qr) {
              setQrCode(qr.url);
            }

            var dl = paymentResult.data.actions.find(
              (item) => item.name === "deeplink-redirect"
            );
            if (dl) {
              setDeeplink(dl.url);
            }
          }
        }
      }
    } else {
      transactionStatusCommerce(params["invoice_code"] as string);
    }
  }, [paymentResult]);

  return (
    <div
      style={{
        margin: 20,
      }}
    >
      <Skeleton loading={transactionDetailLoading}>
        <h1>{channel?.title}</h1>
        <h2>{channel?.subtitle}</h2>
        {transactionDetail?.status ? (
          <>
            <Card
              hoverable
              style={{
                marginBottom: 10,
              }}
            >
              <p className="amount">Jumlah</p>
              <div
                style={{
                  float: "right",
                }}
              >
                <span className="amount-rp">Rp </span>
                <span className="amount-number">
                  {thousandSeparator(transactionDetail?.data?.total_amount)}
                </span>
              </div>
            </Card>
            {channel?.type === ChannelType.EWALLET && (
              <Card
                hoverable
                style={{
                  marginBottom: 10,
                  textAlign: "center",
                }}
              >
                {/* <span className="account-number-text">
                  {channel.additional_text
                    ? channel.additional_text[0]
                    : "Error"}
                </span> */}
                <br />
                {qrCode !== undefined ? (
                  <img
                    ref={imageRef}
                    alt={qrCode}
                    src={qrCode}
                    style={{
                      textAlign: "center",
                      minWidth: "6.5cm",
                      maxWidth: "6.5cm",
                    }}
                  />
                ) : null}
                <Divider />
                {deeplink !== undefined && (
                  <a
                    className="deeplink"
                    target={"_blank"}
                    rel="noreferrer"
                    href={deeplink}
                  >
                    {channel.additional_text
                      ? channel.additional_text[1]
                      : "Error"}
                  </a>
                )}
                {qrCode !== undefined ? (
                  <Button
                    type="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      // Replace 'image-url.jpg' with the actual URL or local path of your image
                      fetch(
                        `${process.env.REACT_APP_BASE_URL}/api/payment_downloadqr?qr_code=${qrCode}`,
                        {
                          method: "GET",
                        }
                      )
                        .then((response) => response.blob())
                        .then((blob) => {
                          const url = window.URL.createObjectURL(
                            new Blob([blob])
                          );

                          const link = document.createElement("a");
                          link.href = url;
                          link.download = `qris.jpg`;

                          document.body.appendChild(link);

                          link.click();

                          link.parentNode.removeChild(link);
                        })
                        .catch((e) => {
                          console.log(e);
                        });
                    }}
                  >
                    Download QRIS
                  </Button>
                ) : null}
              </Card>
            )}
            {bankCode && (
              <Card
                hoverable
                style={{
                  marginBottom: 10,
                }}
              >
                <p className="amount">Kode Bank</p>
                <div
                  style={{
                    float: "right",
                  }}
                >
                  <span className="amount-number">{bankCode}</span>
                </div>
              </Card>
            )}
            {channel?.type !== ChannelType.EWALLET ? (
              <Card
                style={{
                  marginBottom: 10,
                }}
              >
                {vaNumber && (
                  <div style={{ float: "left" }}>
                    <span className="account-number-text-block">
                      Nomor Rekening
                    </span>
                    <span className="account-number">{vaNumber}</span>
                    <br />
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        message.info(`Nomor Rekening berhasil di salin.`);
                        navigator.clipboard.writeText(vaNumber);
                      }}
                    >
                      Salin Nomor Rekening
                    </a>
                  </div>
                )}
              </Card>
            ) : (
              <div></div>
            )}
          </>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            // description={paymentResult?.data?.status_message}
            description={"paymentResult?.data?.status_message"}
          />
        )}
      </Skeleton>

      <Card hoverable title={"Detail Transaksi"} style={{ marginBottom: 10 }}>
        <span className="transaction-detail-text">Items</span>
        <ul>
          {transactionDetail?.data?.trx?.map((item, index) => {
            return (
              <li key={item.id}>
                {index + 1}. {item.product?.name}
              </li>
            );
          })}
        </ul>
      </Card>

      {channel?.hows?.length > 0 ? (
        <Card hoverable title={"Cara Pembayaran"} style={{ marginBottom: 10 }}>
          <Tabs>
            {channel?.hows?.map((item, index) => (
              <Tabs.TabPane tab={item.name} key={index}>
                {item.steps?.map((item, index) => (
                  <p key={index}>
                    {index + 1}. {item}
                  </p>
                ))}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Card>
      ) : (
        <></>
      )}
    </div>
  );
}

export default PaymentMidtransCommerce;
