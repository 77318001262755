import React from "react";
import { ChannelModel } from "../../models/ChannelModel";
import { ChannelProvider, ChannelType } from "./ChannelProvider";
import {
  ALFAMARTIPaymu,
  ALFAMARTMidtrans,
  BANKOTHERVAMidtrans,
  BCAVAIPaymu,
  BCAVAMidtrans,
  BCAVANicepay,
  BNIVAIPaymu,
  BNIVAMidtrans,
  BNIVANicepay,
  BRIVAIPaymu,
  BRIVAMidtrans,
  BRIVANicepay,
  GOPAYMidtrans,
  INDOMARETIPaymu,
  MANDIRIVAIPaymu,
  MANDIRIVAMidtrans,
  OVOMidtrans,
  MANDIRIVANicepay,
  PERTAMAVAMidtrans,
  QRISIPaymu,
  QRISMidtrans,
  SHOPEEMidtrans,
} from "./interfaces/midtrans-channel";

export type ChannelContextType = {
  channels: ChannelModel[];
  midtransChannels: ChannelModel[];
  ipaymuChannels: ChannelModel[];
  nicepayChannels: ChannelModel[];
  walletChannels: ChannelModel[];
};

export const ChannelContext = React.createContext<ChannelContextType | null>(
  null
);

export const ChannelContextProvider: React.FC<React.ReactNode> = ({
  children,
}) => {
  const [walletChannels, setWalletChannels] = React.useState<ChannelModel[]>(
    []
  );
  const [channels, setChannels] = React.useState<ChannelModel[]>([]);
  const [midtransChannels, setMidtransChannels] = React.useState<
    ChannelModel[]
  >([]);
  const [ipaymuChannels, setIpaymuChannels] = React.useState<ChannelModel[]>(
    []
  );
  const [nicepayChannels, setNicepayChannels] = React.useState<ChannelModel[]>(
    []
  );

  React.useEffect(() => {
    getPaymentChannels();
  }, []);

  const getChannel = () => {
    var channelProvider: ChannelProvider = new ChannelProvider();
    let c = channelProvider.getChannels();
    setMidtransChannels(c);
  };

  const getPaymentChannels = async () => {
    var channelProvider: ChannelProvider = new ChannelProvider();
    const response = await channelProvider.getPaymentChannels();
    const channels: ChannelModel[] = [];
    if (response?.status) {
      if (response.data?.items?.length > 0) {
        for (const item of response.data?.items) {
          if (
            item.description.toLowerCase() !== "midtrans" &&
            item.description.toLowerCase() !== "ipaymu" &&
            item.description.toLowerCase() !== "wallet" &&
            item.description.toLowerCase() !== "nicepay"
          ) {
            channels.push({
              id: item.id,
              thumbnail: `${item.name?.toLowerCase()} sprite`,
              type: ChannelType.MANUAL_BANK_TRANSFER,
              payment_type: "manual_bank_transfer",
              code: item.name?.toLowerCase(),
              title: item.name,
              subtitle: item.description,
              hows: [
                {
                  name: "Transfer Manual",
                  steps: [
                    "Pilih Transfer pada menu utama bank pilihan Anda. Transfer bisa dilakukan melalui ATM, SMS Banking, atau Internet Banking.",
                    "Masukkan nomor rekening di atas. Kemudian, masukkan nominal sesuai dengan jumlah yang tertera pada nominal diatas.",
                  ],
                },
              ],
              account_number: item.account_number,
            });
          }
        }

        // wallet
        for (const item of response.data?.items) {
          if (item.description.toLowerCase() === "wallet") {
            setWalletChannels((state) => [
              ...state,
              {
                id: item.id,
                thumbnail: `${item.name?.toLowerCase()} sprite`,
                type: ChannelType.UNIVERSA_WALLET,
                payment_type: "wallet",
                code: item.name?.toLowerCase(),
                title: item.name,
                subtitle: "Pembayaran menggunakan Wallet",
                hows: [
                  {
                    name: "Wallet",
                    steps: ["Dipotong langsung dari saldo wallet anda"],
                  },
                ],
                account_number: item.account_number,
              },
            ]);
          }
        }

        // Midtrans Payment
        for (const item of response.data?.items) {
          if (item.description.toLowerCase() === "midtrans") {
            console.log(item.name.toLowerCase());
            switch (item.name.toLowerCase()) {
              case "bca virtual account":
                setMidtransChannels((state) => [
                  ...state,
                  {
                    id: item.id,
                    ...BCAVAMidtrans,
                  },
                ]);
                break;
              case "mandiri virtual account":
                setMidtransChannels((state) => [
                  ...state,
                  {
                    id: item.id,
                    ...MANDIRIVAMidtrans,
                  },
                ]);
                break;
              case "bni virtual account":
                setMidtransChannels((state) => [
                  ...state,
                  {
                    id: item.id,
                    ...BNIVAMidtrans,
                  },
                ]);
                break;
              case "bri virtual account":
                setMidtransChannels((state) => [
                  ...state,
                  {
                    id: item.id,
                    ...BRIVAMidtrans,
                  },
                ]);
                break;
              case "permata virtual account":
                setMidtransChannels((state) => [
                  ...state,
                  {
                    id: item.id,
                    ...PERTAMAVAMidtrans,
                  },
                ]);
                break;
              case "bank lainnya":
                setMidtransChannels((state) => [
                  ...state,
                  {
                    id: item.id,
                    ...BANKOTHERVAMidtrans,
                  },
                ]);
                break;
              case "gopay":
                setMidtransChannels((state) => [
                  ...state,
                  {
                    id: item.id,
                    ...GOPAYMidtrans,
                  },
                ]);
                break;
              case "ovo":
                setMidtransChannels((state) => [
                  ...state,
                  {
                    id: item.id,
                    ...OVOMidtrans,
                  },
                ]);
                break;
              case "shopee":
                setMidtransChannels((state) => [
                  ...state,
                  {
                    id: item.id,
                    ...SHOPEEMidtrans,
                  },
                ]);
                break;
              case "qris":
                setMidtransChannels((state) => [
                  ...state,
                  {
                    id: item.id,
                    ...QRISMidtrans,
                  },
                ]);
                break;
              case "alfamart":
                setMidtransChannels((state) => [
                  ...state,
                  {
                    id: item.id,
                    ...ALFAMARTMidtrans,
                  },
                ]);
                break;
            }
          }
        }

        // IPAYMU Payment
        for (const item of response.data?.items) {
          if (item.description?.toLowerCase() === "ipaymu") {
            console.log(item.name.toLowerCase());
            switch (item.name.toLowerCase()) {
              case "bca virtual account":
                setIpaymuChannels((state) => [
                  ...state,
                  {
                    id: item.id,
                    ...BCAVAIPaymu,
                  },
                ]);
                break;
              case "mandiri virtual account":
                setIpaymuChannels((state) => [
                  ...state,
                  {
                    id: item.id,
                    ...MANDIRIVAIPaymu,
                  },
                ]);
                break;
              case "qris":
                setIpaymuChannels((state) => [
                  ...state,
                  {
                    id: item.id,
                    ...QRISIPaymu,
                  },
                ]);
                break;
              case "alfamart":
                setIpaymuChannels((state) => [
                  ...state,
                  {
                    id: item.id,
                    ...ALFAMARTIPaymu,
                  },
                ]);
                break;
              case "indomaret":
                setIpaymuChannels((state) => [
                  ...state,
                  {
                    id: item.id,
                    ...INDOMARETIPaymu,
                  },
                ]);
                break;
              case "bni virtual account":
                setIpaymuChannels((state) => [
                  ...state,
                  {
                    id: item.id,
                    ...BNIVAIPaymu,
                  },
                ]);
                break;
              case "bri virtual account":
                setIpaymuChannels((state) => [
                  ...state,
                  {
                    id: item.id,
                    ...BRIVAIPaymu,
                  },
                ]);
                break;
            }
          }
        }

        // NICEPAY Payment
        for (const item of response.data?.items) {
          if (item.description?.toLowerCase() === "nicepay") {
            console.log(item.name.toLowerCase());
            switch (item.name.toLowerCase()) {
              case "bca virtual account":
                setNicepayChannels((state) => [
                  ...state,
                  {
                    id: item.id,
                    ...BCAVANicepay,
                  },
                ]);
                break;
              case "mandiri virtual account":
                setNicepayChannels((state) => [
                  ...state,
                  {
                    id: item.id,
                    ...MANDIRIVANicepay,
                  },
                ]);
                break;
              // case "qris":
              // setNicepayChannels((state) => [
              //   ...state,
              //   {
              //     id: item.id,
              //     ...QRISNicepay,
              //   },
              // ]);
              // break;
              // case "alfamart":
              // setNicepayChannels((state) => [
              //   ...state,
              //   {
              //     id: item.id,
              //     ...ALFAMARTNicepay,
              //   },
              // ]);
              // break;
              // case "indomaret":
              // setNicepayChannels((state) => [
              //   ...state,
              //   {
              //     id: item.id,
              //     ...INDOMARETNicepay,
              //   },
              // ]);
              // break;
              case "bni virtual account":
                setNicepayChannels((state) => [
                  ...state,
                  {
                    id: item.id,
                    ...BNIVANicepay,
                  },
                ]);
                break;
              case "bri virtual account":
                setNicepayChannels((state) => [
                  ...state,
                  {
                    id: item.id,
                    ...BRIVANicepay,
                  },
                ]);
                break;
            }
          }
        }
      }
    }
    setChannels(channels);
  };

  return (
    <ChannelContext.Provider
      value={{
        channels,
        midtransChannels,
        ipaymuChannels,
        walletChannels,
        nicepayChannels,
      }}
    >
      {children}
    </ChannelContext.Provider>
  );
};
