import { AxiosError } from "axios"
import { SingleFileUploadResult } from "./single-file-upload.result"
import Api from "../../api/Api"

export class UploadFileProvider {
private api: Api

constructor() {
    this.api = new Api();
  }

  uploadSingle = async (
    file: File,
    progress: (e: ProgressEvent) => void
  ): Promise<SingleFileUploadResult> => {
    try {
      const formData = new FormData()
      formData.append('file', file)
  
      let res = await this.api.apiUploadFile.post<SingleFileUploadResult>('/storage/single', formData, {
        onUploadProgress: (pg: ProgressEvent) => {
          progress(pg)
        },
      })  
      return res.data as SingleFileUploadResult
    } catch (e) {
      let a = e as AxiosError
      let response: SingleFileUploadResult = a.response?.data
      return response
    }
  }
}
